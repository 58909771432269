import Ticket from "amnPos/ticket/Ticket";
import {
  PosHandler,
  HandlerProps,
  IHandlerResult,
  HandlerStatus,
} from "../../../models/handlers/handler";

export class DiscountHandler extends PosHandler<Ticket> {
  protected processHandle(
    props: HandlerProps<Ticket>
  ): Promise<IHandlerResult<Ticket>> {
    const { item: ticket, value } = props;

    ticket.discountValue = ticket.ticketItems.reduce(
      (disc, item) => disc.plus(item.discountValue),
      0
    );
    return Promise.resolve({
      item: ticket,
      status: HandlerStatus.Success,
    });
  }
}
