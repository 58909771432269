import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import IAsyncModel from "models/IAsyncModel";
import { Language } from "./Language";
import LanguageApi from "./LanguageApi";
import { RootState } from "app/store";

export interface Languages extends IAsyncModel {
  data: Language[];
}

const initialState: Languages = { data: [] };

export const fetchLanguagesAsync = createAsyncThunk("languages/", async () => {
  const resp = await LanguageApi.LanguageList();

  if (resp.data) {
    return { data: resp.data } as Languages;
  }

  return initialState;
});

export const LanguagesSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {
    initializeLanguages: () => {
      return initialState;
    },
    resetLanguages: (state, action: PayloadAction<Languages>) => {
      state = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLanguagesAsync.fulfilled, (_, action) => {
        return action.payload;
      })
      .addCase(fetchLanguagesAsync.rejected, () => {
        return initialState;
      });
  },
});

export const { initializeLanguages, resetLanguages } = LanguagesSlice.actions;

export const languages = (state: RootState) => state.languages;

export default LanguagesSlice.reducer;
