import  { Snackbar } from "components/general/Snackbar";
import {
  HandlerProps,
  HandlerStatus,
  IHandler,
  IHandlerResult,
} from "./handler";
import { useContext } from "react";
import useTranslate from "hooks/useTranslate";
import MessageDialogContext from "context/MessageDialogContext";
import { store } from "app/store";

export interface ICallbackTypes<T> {
  onSuccess: (result: T) => void;
  onFailure?: (resul?: T) => void;
}

export interface ICallbackTypesAsync<T> {
  onSuccess(result: T): Promise<void>;
  onFailure?(result?: T): Promise<void>;
}

export default function useExecuteHandler() {

  const messageDialog = useContext(MessageDialogContext);
  const { translate } = useTranslate("pos.message.error");
  const user = store.getState().user;

  const executeHandler = async <T>(
    handler: IHandler<T>,
    props: HandlerProps<T>
  ): Promise<IHandlerResult<T>> => {
    return await handler.handle({
      item: structuredClone(props.item),
      value: props.value,
    });
  };

  const executeHandlerWithCallback = <T>(
    handler: IHandler<T>,
    props: HandlerProps<T>,
    callback: ICallbackTypes<T>
  ) => {
    executeHandler(handler, props).then((resp) => {
      switch (resp.status) {
        case HandlerStatus.Success: {
          callback.onSuccess(resp.item);
          break;
        }
        case HandlerStatus.Error: {
          if (!resp.validaionResult!.errorMsgTitle)
            Snackbar(
              translate(resp.validaionResult!.errorMsg),
              "error"
            );
          else {
            messageDialog.setErrorMessageInfo(
              translate(resp.validaionResult!.errorMsgTitle),
              translate(resp.validaionResult!.errorMsg).replace(
                "{{value}}",
                user.itemDisCountPercentage.toString()
              )
            );
          }
          if (callback.onFailure) callback.onFailure();
          break;
        }
      }
    });
  };

  const executeHandlerWithCallbackAsync = async <T>(
    handler: IHandler<T>,
    props: HandlerProps<T>,
    callback: ICallbackTypesAsync<T>
  ) => {
    const result = await executeHandler(handler, props);
    switch (result.status) {
      case HandlerStatus.Success: {
        await callback.onSuccess(result.item);
        break;
      }
      case HandlerStatus.Error: {
        Snackbar(
          translate(result.validaionResult!.errorMsg),
          "error"
        );
        if (callback.onFailure) await callback.onFailure();
        break;
      }
    }
  };

  const executeHandleAsync = async <T>(
    handler: IHandler<T>,
    props: HandlerProps<T>
  ) => {
    const result = await executeHandler(handler, props);
    return Promise.resolve(result);
  };

  return {
    executeHandler,
    executeHandleAsync,
    executeHandlerWithCallback,
    executeHandlerWithCallbackAsync,
  };
}
