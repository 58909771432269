import { Box } from "@mui/material";
import Lottie from "lottie-react";
import { CSSProperties, useEffect, useState } from "react";
type LogoProps = {
    width: string;
    height: string;
    style?: CSSProperties;

}
const SignLogo = ( prop : LogoProps) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        fetch('/icons/POSloginanimation.json')
        .then(response => response.json())
        .then(data => setData(data));
    }, []);

    return<Box  width={prop.width} height={prop.height}>
        <Lottie animationData={data}/>
    </Box>
};

export default SignLogo;
