import axios from "axios";
import getApiBaseUrl from "helpers/getApiBaseUrl";
import { Language } from "./Language";
import { LanguageDto } from "./LanguageDto";

const baseUrl = `${getApiBaseUrl()}localizations`;

const LanguageList = () => axios.get<Language[]>(baseUrl);

const createLanguage = (language: LanguageDto) => axios.post(baseUrl, language);
const DeleteLanguage = (id: string) => axios.delete(`${baseUrl}/${id}`);

const LanguageApi = { LanguageList, createLanguage, DeleteLanguage };

export default LanguageApi;
