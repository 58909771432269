import Ticket from "amnPos/ticket/Ticket";
import {
  PosHandler,
  HandlerProps,
  IHandlerResult,
  HandlerStatus,
} from "../../../models/handlers/handler";

export class CodeHandler extends PosHandler<Ticket> {
  protected processHandle(
    props: HandlerProps<Ticket>
  ): Promise<IHandlerResult<Ticket>> {
    const { item, value } = props;
    if (value) {
      item.code = value as string;
    } else item.code = "s";
    return Promise.resolve({
      item: item,
      status: HandlerStatus.Success,
    });
  }
}
