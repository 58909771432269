import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import { Suspense, createContext, useCallback, useMemo, useState } from "react";
import { Stack } from "@mui/system";
import IChildrenProps from "models/common-props/IChildrenProps";
import { ConfirmationDialogType } from "constants/confirmationDialogType";
import useTranslate from "hooks/useTranslate";
import { AmeenColors, AmeenColorsDark } from "constants/ameenColors";
import React from "react";
import MainButton from "amnPos/generalComponent/MainButton";
const ConfirmationDialogIcon = React.lazy(
  () => import("components/icons/ConfirmationDialogIcon")
);

const ConfirmationDialogContext = createContext({
  setConfirmAction: (
    messageHeader: string,
    messageDescription: string,
    action: Function,
    messageType: ConfirmationDialogType,
    showConfirm: boolean = true,
    closeBtnTextKey?: string,
    performActionAndCloseBtnTextKey?: string,
    performActionAndCloseBtnPrimaryColor?: boolean
  ) => {},
});

export function ConfirmationDialogContextProvider({
  children,
}: IChildrenProps) {
  const { buttonTrans } = useTranslate("");
  const [open, setOpen] = useState(false);
  const [messageHeader, setMessageHeader] = useState("");
  const [messageDescription, setMessageDescription] = useState("");
  const [action, setAction] = useState<Function | null>(null);
  const [type, setType] = useState<ConfirmationDialogType>("");
  const [closeBtnText, setCloseBtnText] = useState<string | undefined>(
    undefined
  );
  const [performActionAndCloseBtnText, setPerformActionAndCloseBtnText] =
    useState<string | undefined>(undefined);
  const [
    performActionAndCloseBtnPrimaryColor,
    setPerformActionAndCloseBtnPrimaryColor,
  ] = useState<Boolean | undefined>(undefined);
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  function performActionAndClose() {
    if (action) action();
    handleClose();
  }

  function handleClose() {
    setOpen(false);
    setAction(null);
  }

  const setConfirmAction = useCallback(
    (
      messageHeader: string,
      messageDescription: string,
      action: Function,
      messageType: ConfirmationDialogType,
      showConfirm: boolean = true,
      closeBtnTextKey?: string,
      performActionAndCloseBtnTextKey?: string,
      performActionAndCloseBtnPrimaryColor?: boolean
    ) => {
      if (showConfirm) {
        setOpen(true);
        setMessageHeader(messageHeader);
        setMessageDescription(messageDescription);
        setAction(() => action);
        setType(messageType);
        setCloseBtnText(closeBtnTextKey);
        setPerformActionAndCloseBtnText(performActionAndCloseBtnTextKey);
        setPerformActionAndCloseBtnPrimaryColor(
          performActionAndCloseBtnPrimaryColor
        );
      } else {
        action();
      }
    },
    []
  );

  const context = useMemo(() => ({ setConfirmAction }), [setConfirmAction]);

  return (
    <ConfirmationDialogContext.Provider value={context}>
      {children}
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <Stack alignItems="center" position="relative">
          <DialogTitle
            id="confirm-dialog-title"
            style={{
              borderRadius: "50%",
              boxShadow: isDark
                ? "0px 8px 13px rgba(0, 0, 0, 0.20)"
                : "0px 4px 4px rgba(0, 0, 0, 0.07)",
              position: "absolute",
              top: "-50px",
              padding: "10px",
            }}
          >
            <Suspense fallback={<div>Loading ...</div>}>
              <ConfirmationDialogIcon iconType={type} isDark={isDark} />
            </Suspense>
          </DialogTitle>
        </Stack>

        <Stack alignItems={"center"}>
          <DialogContent
            sx={{
              mt: "60px",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 600,
                marginBottom: "3px",
              }}
            >
              {messageHeader}
            </Typography>

            {messageDescription && (
              <DialogContentText
                id="confirm-dialog-description"
                paddingTop="5px"
                sx={{
                  fontSize: "18px",
                  textAlign: "center",
                  color: isDark ? "#A5A5A5" : "#667987",
                }}
              >
                {messageDescription}
              </DialogContentText>
            )}
          </DialogContent>

          <DialogActions sx={{ marginBottom: "24px" }}>
            <Stack direction={"row"} spacing={2}>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                  border: isDark
                    ? "1px solid rgba(255, 255, 255, 0.50)"
                    : "1px solid rgba(2, 11, 18, 0.50)",
                  borderColor: "inherit",
                  color: isDark ? "white" : AmeenColors.HardBlack,
                  backgroundColor: "inherit",
                  ":hover": { backgroundColor: "#1976D2", color: "white" },
                }}
              >
                {closeBtnText ??
                  buttonTrans(type === "WARNING" ? "stayHere" : "cancel")}
              </Button>
              <MainButton
                backgroundColor={
                  performActionAndCloseBtnPrimaryColor
                    ? AmeenColors.Primary
                    : AmeenColors.Red
                }
                fontColor={isDark ? AmeenColorsDark.ImageBackground : "white"}
                darkBackgroundColor={
                  performActionAndCloseBtnPrimaryColor
                    ? AmeenColorsDark.Primary
                    : AmeenColorsDark.HardRed
                }
                onClick={performActionAndClose}
                children={
                  performActionAndCloseBtnText ??
                  buttonTrans(type === "WARNING" ? "discardChanges" : "delete")
                }
              />
            </Stack>
          </DialogActions>
        </Stack>
      </Dialog>
    </ConfirmationDialogContext.Provider>
  );
}

export default ConfirmationDialogContext;
