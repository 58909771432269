import { ItemDiscount, TicketItem } from "amnPos/ticket/Ticket";
import {
  PosHandler,
  HandlerProps,
  IHandlerResult,
  HandlerStatus,
} from "../../../models/handlers/handler";
import { DiscountValidator } from "amnPos/validators/ticketItemValidators/DiscountValidator";

export class DiscountHandler extends PosHandler<TicketItem> {
  constructor() {
    super();
    this.validator = new DiscountValidator();
  }

  protected processHandle(
    props: HandlerProps<TicketItem>
  ): Promise<IHandlerResult<TicketItem>> {
    const { item, value } = props;

    if (value) {
      if ((value as ItemDiscount).discPercent?.isGreaterThanOrEqualTo(0)) {
        item.discountPercent = Number((value as ItemDiscount).discPercent);

        item.discountValue = Number((value as ItemDiscount).discPercent)
          .multipliedBy(item.total)
          .dividedBy(100);
      } else if ((value as ItemDiscount).discValue?.isGreaterThanOrEqualTo(0)) {
        item.discountValue = Number((value as ItemDiscount).discValue);

        item.discountPercent = Number((value as ItemDiscount).discValue)
          .dividedBy(item.total)
          .multipliedBy(100);
      }
    }

    item.discountPercent = item.discountValue
      .dividedBy(item.total)
      .multipliedBy(100);

    return Promise.resolve({
      item: item,
      status: HandlerStatus.Success,
    });
  }
}
