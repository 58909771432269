import axios from "axios";
import getApiBaseUrl from "helpers/getApiBaseUrl";
import IPosUserLoginDto from "adminPanel/pos/models/IPosUserLoginDto";
import IPosUserLoginResponseDto from "adminPanel/pos/models/IPosUserLoginResponseDto";
import IOrganizationLoginDto from "adminPanel/user/models/IOrganizationLoginDto";
import IOrganziatonLoginResponseDto from "adminPanel/user/models/IOrganziatonLoginResponseDto";
import IOrganizationSignUpDto from "adminPanel/user/models/IOrgnizationSignUpDto";
import IUserConnectedDto from "adminPanel/user/models/IUserConnectedDto";
import IUserLoginDto from "adminPanel/user/models/IUserLoginDto";
import IUserLoginResponseDto from "adminPanel/user/models/IUserLoginResponseDto";
import { IUserProfileInfo } from "./models/IUser";

const baseUrl = getApiBaseUrl();

function getConnected(posId?: string) {
  axios.defaults.headers.common["Accept-Language"] =
    localStorage.getItem("localLanguage") || "en-US"; 
  return axios.get<IUserConnectedDto>(`${baseUrl}users/connected/${posId}`);
}

function loginOrganization(dto: IOrganizationLoginDto) {
  return axios.post<IOrganziatonLoginResponseDto>(
    baseUrl + "organization/login",
    dto
  );
}

function login(dto: IUserLoginDto) {
  return axios.post<IUserLoginResponseDto>(baseUrl + "users/login", {
    user: dto,
  });
}

function loginPos(dto: IPosUserLoginDto) {
  return axios.post<IPosUserLoginResponseDto>(baseUrl + "employee/login", dto);
}

function signUp(dto: IOrganizationSignUpDto) {
  return axios.post<string>(baseUrl + "organization/create", { dto: dto });
}

function signOut() {
  return axios.get(baseUrl + "users/sign-out");
}

function verifyOrganizationEmail(id: string) {
  return axios.get(baseUrl + "organization/verify/" + id);
}

function resendVerify(email?: string) {
  return axios.get(baseUrl + "organization/resend-verify-email", {
    params: { email: email },
  });
}

function getUserProfileInfo(id: string) {
  return axios.get(`${baseUrl}profiles/${id}`);
}

function editUserProfileInfo(user: IUserProfileInfo) {
  return axios.post(`${baseUrl}profiles`, { dto: user });
}

const userApi = {
  getConnected,
  loginOrganization,
  login,
  loginPos,
  signUp,
  signOut,
  verifyOrganizationEmail,
  resendVerify,
  getUserProfileInfo,
  editUserProfileInfo,
};

export default userApi;
