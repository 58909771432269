import {
  createContext,
  Suspense,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { Backdrop } from "@mui/material";
import IChildrenProps from "models/common-props/IChildrenProps";
import React from "react";
const PosLoadingIcon = React.lazy(
  () => import("components/icons/PosLoadingIcon")
);
interface LoadingBackDropContextType {
  setLoadingBackDropAction: (show: boolean, delay?: number) => void;
  isLoading: () => boolean;
}

const LoadingBackDropContext = createContext<LoadingBackDropContextType>({
  setLoadingBackDropAction: () => {},
  isLoading: () => false,
});

export function LoadingBackDropContextProvider({ children }: IChildrenProps) {
  const [open, setOpen] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const isLoading = useCallback(() => {
    return open;
  }, [open]);

  const setLoadingBackDropAction = useCallback(
    (show: boolean, delay: number = 1000) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      if (show) {
        if (delay > 0) {
          timeoutRef.current = setTimeout(() => {
            setOpen(true);
          }, delay);
        } else {
          setOpen(true);
        }
      } else {
        setOpen(false);
      }
    },
    []
  );

  const context = useMemo(
    () => ({ setLoadingBackDropAction, isLoading }),
    [setLoadingBackDropAction, isLoading]
  );

  return (
    <LoadingBackDropContext.Provider value={context}>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 111,
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        }}
        open={open}
      >
        <Suspense fallback={<div>Loading ...</div>}>
          <PosLoadingIcon isDark={false} />
        </Suspense>
      </Backdrop>
      {children}
    </LoadingBackDropContext.Provider>
  );
}

export default LoadingBackDropContext;

export function useLoadingBackDropDialogContext() {
  const confirm = useContext(LoadingBackDropContext);
  return confirm;
}
