import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { NIL, NIL as uuidNil } from "uuid";

export interface RestaurantsMenuState {
  pageNumber: number;
  pageSize: number;
  TotalRecords: number;
  searchText: string;
  menuId: string;
  sectionId: string;
  subSectionId: string;
}

var initialState: RestaurantsMenuState = {
  pageNumber: 1,
  pageSize: 16,
  TotalRecords: 0,
  searchText: "",
  menuId: NIL,
  sectionId: NIL,
  subSectionId: NIL,
};

export const restaurantsMenuSlice = createSlice({
  name: "RestaurantsMenuSlice",
  initialState,
  reducers: {
    initialize: (state) => {
      return state;
    },
    changeTotalRows: (state, action: PayloadAction<number>) => {
      state.TotalRecords = action.payload;
    },
    onChangePage: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    onChangePageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    onSearchChange: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    onSetMenuId: (state, action: PayloadAction<string>) => {
      state.menuId = action.payload;
    },
    onSetSectionId: (state, action: PayloadAction<string>) => {
      state.sectionId = action.payload;
    },
    onSetSubSectionId: (state, action: PayloadAction<string>) => {
      state.subSectionId = action.payload;
    },
  },
});

export const {
  initialize,
  changeTotalRows,
  onChangePage,
  onChangePageSize,
  onSearchChange,
  onSetMenuId,
  onSetSectionId,
  onSetSubSectionId,
} = restaurantsMenuSlice.actions;

export const restMenu = (state: RootState) => state.restMenu;

export default restaurantsMenuSlice.reducer;
