import { useCallback, useContext } from "react";
import shiftAPI from "./shiftAPI";
import { OpenShiftStatus, OpenShiftResult } from "./ShiftDataModel";
import reportHttpErrors from "helpers/reportHttpErrors";
import {Snackbar} from "components/general/Snackbar";
import MessageDialogContext from "context/MessageDialogContext";
import useTranslate from "hooks/useTranslate";

export default function useShift() {
  const { translate } = useTranslate("pos.shiftsAndExternals");

  const messageDialog = useContext(MessageDialogContext);

  const HandelValidationMsg = useCallback(
    (parms: { result: OpenShiftResult; useSnackbar?: boolean }) => {
      let message = "";
      const { useSnackbar = false } = parms;
      switch (parms.result.status) {
        case OpenShiftStatus.ShiftWithSameUserAnotherDevice:
          message = translate(
            `message.error.shiftWithSameUserAnotherDevice`
          ).replace("{{posName}}", parms.result.shiftInfo?.pos!);
          break;
        case OpenShiftStatus.ShiftWithAnotherUserSameDevice:
          message = translate(
            `message.error.shiftWithAnotherUserSameDevice`
          ).replace("{{userName}}", parms.result.shiftInfo?.employee!);
          break;
      }

      if (useSnackbar === false)
        messageDialog.setErrorMessageInfo(
          translate("message.error.connotLogInTitle"),
          message
        );
      else Snackbar(message, "error");
    },
    [messageDialog, Snackbar, translate]
  );
  const ValidateShift = useCallback(
    (posId: string, onSuccess?: any) => {
      shiftAPI
        .ValidateShift(posId)
        .then((rep) => {
          if (rep.data.status === OpenShiftStatus.Success) {
            if (onSuccess) onSuccess();
          } else HandelValidationMsg({ result: rep.data });
        })
        .catch((e) => {
          reportHttpErrors(e.response, Snackbar);
        });
    },
    [Snackbar, HandelValidationMsg]
  );

  return {
    ValidateShift,
    HandelValidationMsg,
  };
}
