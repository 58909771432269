import { InputAdornment, useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import { forwardRef } from "react";

const StandardTextField = forwardRef((props: any, ref) => {
  const { startIcon,size,margin ,endIcon, ...other } = props;
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === "dark";

  return (
    <TextField
    margin={margin}
    size={size}
    ref={ref}
    variant="standard"
    {...other}
    InputProps={{
      startAdornment: startIcon ? (
        <InputAdornment
          sx={{ color: isDarkTheme ? "" : "#08233F" }}
          position="start" 
        >
          {startIcon}
        </InputAdornment>
      ) : undefined,
      endAdornment: endIcon ? (
        <InputAdornment
          sx={{ color: isDarkTheme ? "" : "#08233F" }}
          position="end"
        >
          {endIcon}
        </InputAdornment>
      ) : undefined,
    }}
  />
);
});

export default StandardTextField;
