import ItemApi from "amnPos/item/itemApi";
import { useTicketItem } from "amnPos/ticket/item/useTicketItem";
import { useAppDispatch, useAppSelector } from "app/hooks";
import reportHttpErrors from "helpers/reportHttpErrors";
import {
  setBarcodeText,
  setIsReady,
  setItems,
} from "slices/barcode/barcodeSlice";
import { useContext, useEffect, useState } from "react";
import MessageDialogContext from "context/MessageDialogContext";
import useTranslate from "./useTranslate";
import { playBeep } from "helpers/playBeep";
import { Snackbar } from "components/general/Snackbar";
export default function useHandleBarcodeActions() {
  const [open, setOpen] = useState(false);
  const [play, setPlay] = useState(false);
  const dispatch = useAppDispatch();
  const barcodeState = useAppSelector((x) => x.barcode);
  const { addItem } = useTicketItem({});
  const messageDialog = useContext(MessageDialogContext);
  const { messageTrans } = useTranslate("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (play) {
      const interval = setInterval(() => {
        playBeep();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [play]);

  const handelAddItem = (text: string) => {
    setIsLoading(true);
    if (!isLoading) {
      if (text) {
        ItemApi.GetItemsByBarcode(text)
          .then((resp) => {
            if (resp.data?.length === 0) {
              dispatch(setIsReady(false));
              setPlay(true);
              messageDialog.setErrorMessageInfo(
                messageTrans("wrongBarcode"),
                messageTrans("wrongBarcodeMessage"),
                "",
                () => {
                  dispatch(setIsReady(true));
                  setPlay(false);
                }
              );
            } else if (resp.data?.length === 1) {
              addItem({ ...resp.data[0], qty: 1 });
            } else {
              setOpen(true);
              dispatch(setIsReady(false));
              dispatch(setItems(resp.data));
            }
          })
          .catch((e) => {
            reportHttpErrors(e.response, Snackbar);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const handelAddItemUsingStoreData = () => {
    if (barcodeState.barcodeText) {
      handelAddItem(barcodeState.barcodeText);
    }
  };

  const preventEnterKeyOnClick = (e: any) => {
    if (e.key === "Enter") {
      dispatch(setBarcodeText(""));
      handelAddItemUsingStoreData();
      e.preventDefault();
    }
  };

  return {
    preventEnterKeyOnClick,
    handelAddItem,
    open,
    setOpen,
  };
}
