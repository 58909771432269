import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { PaymentDetails, PaymentItem } from "models/PaymentModel";

const initialState: PaymentDetails = {
  valueToPaid: 0,
  totalPaid: 0,
  totalRemain: 0,
  payments: [],
} as PaymentDetails;

export const PaymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    initializePaymentSlice: (state) => {
      return initialState;
    },
    updatePayment: (state, action: PayloadAction<PaymentDetails>) => {
      state = action.payload;
      return state;
    },
    updatePaymentItem: (state, action: PayloadAction<PaymentItem>) => {
      state.payments.map((o, key) => {
        if (o.id === action.payload.id) {
          state.payments[key] = action.payload;
        }

        return state;
      });
    },
    deletePaymentItem: (state, action: PayloadAction<string>) => {
      state.payments = state.payments.filter(
        (o) => !action.payload.includes(o.id)
      );
      return state;
    },
  },
});

export const {
  initializePaymentSlice,
  updatePayment,
  updatePaymentItem,
  deletePaymentItem,
} = PaymentSlice.actions;

export const selectTicketItem = (state: RootState) => state.ticket;

export default PaymentSlice.reducer;
