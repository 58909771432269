import { MenuItem, Select, Stack } from "@mui/material";
import FlagIcon from "components/icons/FlagIcon";
import { AmeenColors } from "constants/ameenColors";
import i18n from "localization/i18n";
import { useEffect, useState } from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import useIsSmUp from "hooks/useIsSmUp";

export default function LanguageSelector(props: { [s: string]: any }) {
  const [lang, setLang] = useState(i18n.language);

  const setLanguage = (language: string) => {
    setLang(language);
    localStorage.setItem("localLanguage", language);
    i18n.changeLanguage(language);
    window.location.reload();
  };
  const isSmUp = useIsSmUp();
  useEffect(() => {
    const currentLang = localStorage.getItem("localLanguage");
    if (currentLang === null) {
      const deviceLang = navigator.language;
      setLanguage(deviceLang.split("-")[0]);
    }
  }, []);

  return (
    <Stack direction={"row"} alignItems={"center"} spacing={"8px"} mb={2}>
      <FlagIcon
        code={lang === "en" ? "US" : "EG"}
        width={"20px"}
        height={"13px"}
      />
      <Select
        id="language"
        variant="standard"
        disableUnderline
        value={i18n.language}
        IconComponent={(props) => <KeyboardArrowDownOutlinedIcon {...props} />}
        onChange={(e) => {
          setLanguage(e.target.value);
        }}
        {...props}
        sx={{
          color: AmeenColors.Primary,
          "& .MuiSelect-select": {
            fontSize: "13px",
            fontWeight: 400,
            color: isSmUp ? AmeenColors.Primary : "white",
          },
        }}
      >
        <MenuItem value={"en"}>English</MenuItem>
        <MenuItem value={"ar"}>العربية</MenuItem>
      </Select>
    </Stack>
  );
}
