import {
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Link,
  Alert,
  AlertTitle,
  useTheme,
  Grid2,
  CardMedia,
} from "@mui/material";
import PasswordTextField from "components/general/PasswordTextField";
import StandardTextField from "components/general/StandardTextField";
import FormLogo from "components/icons/FormLogo";
import { useForm } from "react-hook-form";
import useTranslate from "hooks/useTranslate";
import { Link as RouterLink } from "react-router-dom";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  ILoginError,
  LoginErrorStatus,
} from "adminPanel/user/models/LoginErrorStatus";
import { AmeenColors } from "constants/ameenColors";
import LanguageSelector from "components/general/LanguageSelector";
import useIsSmUp from "hooks/useIsSmUp";
import i18n from "localization/i18n";
import SignLogo from "components/icons/SignLogo";
import logo from "/images/SignLogo.svg";
import shap1 from "/images/shap1.svg";
import shap2 from "/images/shap2.svg";
import shap3 from "/images/shap3.svg";
import illustration from "/images/illustration & font.svg";
export interface ILoginFormData {
  name: string;
  password: string;
  rememberMe: boolean;
}

export interface ILoginPageProps {
  submitHandler: (data: ILoginFormData) => void;
  resendEmail: () => void;
  error?: ILoginError;
}

export default function LoginPage({
  submitHandler,
  resendEmail,
  error,
}: ILoginPageProps) {
  const { translate, messageTrans } = useTranslate("shared.signIn");
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === "dark";
  const isSmUp = useIsSmUp();
  const style = {
    container: {
      height: "100vh",
    },
    cardHeader: {
      alignItems: "center",
    },
    containerLogin: {
      display: "flex",
      justifyContent: "center",
      right: 0,
    },
    containerImage: {
      backgroundImage: "url('/images/BG.svg')",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      position: "relative",
    },
    shap1: {
      width: "7vw",
      right: 0,
      position: "absolute",
      objectFit: "contain",
      transform: i18n.language == "ar" ? "scaleX(-1)" : "",
    },
    shap2: {
      width: "13vw",
      bottom: 0,
      position: "absolute",
      objectFit: "contain",
      transform: i18n.language == "ar" ? "scaleX(-1)" : "",
    },
    logo: {
      width: "8vw",
      top: "2%",
      left: "3%",
      position: "absolute",
      objectFit: "contain",
    },
    shap3: {
      width: "13vw",
      right: 0,
      bottom: 0,
      position: "absolute",
      objectFit: "contain",
      transform: i18n.language == "ar" ? "scaleX(-1)" : "",
    },
    illustration: {
      top: "23%",
      position: "absolute",
      objectFit: "cover",
    },
    containerMobileLog: {
      backgroundImage: "url('/images/BGMobile.svg')",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      justifyContent: "center",
      alignItems: "center",
      paddingX: 2,
      height: "85dvh",
    },
    cardContent: {
      width: isSmUp ? "35vw" : "100dvw",
    },
    cardAction: {},
    itemCard: {
      marginBottom: 3,
    },
    title: {
      fontSize: 28,
      bold: 600,
    },
    Link: {
      textDecoration: "none",
      color: AmeenColors.BrightBlue,
      fontWeight: 500,
    },
    langSelect: isSmUp
      ? {
          color: AmeenColors.BrightBlue,
        }
      : {
          position: "absolute",
          top: 22,
          right: i18n.language == "en" ? 5 : "unset",
          left: i18n.language == "ar" ? 5 : "unset",
          color: "white",
        },
    warning: {
      fontSize: 14,
      color: AmeenColors.Red,
    },
  };

  const Images = (
    <Stack position={"relative"} height={"100%"} width={"50%"}>
      <Stack position={"fixed"} height={"100%"} width={"50%"}>
        <CardMedia component={"img"} image={logo} sx={style.logo}></CardMedia>
        <CardMedia component={"img"} image={shap1} sx={style.shap1}></CardMedia>
        <CardMedia component={"img"} image={shap2} sx={style.shap2}></CardMedia>
        <CardMedia component={"img"} image={shap3} sx={style.shap3}></CardMedia>
        <CardMedia
          component={"img"}
          image={illustration}
          sx={style.illustration}
        ></CardMedia>
      </Stack>
    </Stack>
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ILoginFormData>();

  const alert = error && (
    <Alert severity="error" sx={style.warning}>
      <AlertTitle color="#5F2120"> {translate(error.error.name)}</AlertTitle>
      <span>{error.error.value} </span>
    </Alert>
  );

  const cardActions = (
    <Stack sx={style.cardAction}>
      <Button
        sx={style.itemCard}
        variant="contained"
        fullWidth
        onClick={handleSubmit(submitHandler)}
        disableElevation
      >
        {translate("signIn")}
      </Button>
      {error && error.status === LoginErrorStatus.EmailIsNotVerified && (
        <Button fullWidth onClick={resendEmail}>
          {translate("resend")}
        </Button>
      )}
      <Typography color={AmeenColors.GrayHeader} sx={style.itemCard}>
        {translate("createNewAccount")}{" "}
        <Link component={RouterLink} to={"/signup"} sx={style.Link}>
          {translate("signUp")}
        </Link>
      </Typography>
      <Stack sx={style.langSelect}>
        <LanguageSelector />
      </Stack>
    </Stack>
  );

  const cardHeader = (
    <Stack sx={style.cardHeader}>
      <Box>
        <SignLogo width={"300px"} height={"250px"} />
      </Box>
      <Typography sx={style.title} >{translate("login")}</Typography>
      <Stack direction={"row"}>
        <Typography sx={{ color: AmeenColors.GrayWhite, fontWeight: 450,mt:2.3 }}>
          {translate("welcome")}
        </Typography>
        <Typography
          sx={{
            color: AmeenColors.GrayWhite,
            fontWeight: 300,
            paddingLeft: 0.5,
            textAlign: isSmUp ? "center" : "",
            fontSize:16,
            mt:2
          }}
        >
          {translate("loginAccountMessage")}
        </Typography>
      </Stack>
    </Stack>
  );

  const cardContent = (
    <Stack sx={style.cardContent}>
      {cardHeader}
      {error && alert}
      <StandardTextField
        margin="normal"
        size="small"
        variant="outlined"
        fullWidth
        inputProps={register("name", {
          required: {
            value: true,
            message: "requiredError",
          },
        })}
        label={translate("name")}
        error={Boolean(errors.name)}
        helperText={messageTrans(errors.name?.message as string)}
        startIcon={<PersonOutlinedIcon />}
      />
      <PasswordTextField
        margin="normal"
        size="small"
        variant="outlined"
        fullWidth
        label={translate("password")}
        inputProps={register("password", {
          required: {
            value: true,
            message: "requiredError",
          },
        })}
        startIcon={<LockOutlinedIcon />}
        error={Boolean(errors.password)}
        helperText={messageTrans(errors.password?.message as string)}
      />

      <FormControlLabel
        sx={{
          ".MuiFormControlLabel-label": {
            paddingLeft: 1,
            fontSize: 14,
            fontWeight: 500,
            marginTop: 1,
            marginBottom: 4,
          },
        }}
        control={
          <Checkbox
            sx={{
              marginTop: 0,
              marginBottom: 3,
              marginLeft: 2,
              backgroundColor: "white",
              color: watch("rememberMe") ? "blue" : "black",
            }}
            {...register("rememberMe")}
            checked={watch("rememberMe") || false}
          />
        }
        label={translate("rememberMe")}
      />
      {cardActions}
    </Stack>
  );

  return isSmUp ? (
    <Grid2 container sx={style.container}>
      <Grid2 size={6} sx={style.containerImage}>
        {Images}
      </Grid2>
      <Grid2 size={6} sx={style.containerLogin}>
        {cardContent}
      </Grid2>
    </Grid2>
  ) : (
    <Grid2 container sx={style.containerMobileLog}>
      {cardContent}
    </Grid2>
  );
}
