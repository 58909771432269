import { useAppDispatch } from "app/hooks";
import LoginPage, { ILoginFormData } from "components/pages/LoginPage";
import { loginAsync } from "adminPanel/user/userSlice";
import useTranslate from "hooks/useTranslate";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ILoginError, LoginErrorStatus } from "./models/LoginErrorStatus";
import userApi from "./userApi";
import { Snackbar } from "components/general/Snackbar";
import { POSType } from "amnPos/pages/UserHomePage";

export default function UserLoginPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { translate } = useTranslate("admin.user");
  const [error, setError] = useState<ILoginError>();

  function submitHandler(data: ILoginFormData) {
    dispatch(
      loginAsync({
        data: {
          ...data,
          useCookie: true,
        },
        onSuccess: (
          organizationId?: string,
          isCasher?: boolean,
          posId?: string,
          PosUserId?: string,
          posType?: POSType
        ) => {
          Snackbar(translate("loginSuccess"), "success");
          if (isCasher) {
            if (posId != null) {
              localStorage.setItem("posId", posId ?? "");
              localStorage.setItem(
                "posType",
                (posType ?? POSType.POS).toString()
              );
              if (posType == POSType.Restaurants) {
                navigate(`/${organizationId}/restaurants`);
              } else {
                navigate(`/${organizationId}/pos`);
              }
            } else navigate(`/${organizationId}/home`);
          } else navigate(`/${organizationId}`);
        },
        onError: (e) => {
          setError({
            status: e.response.data.status,
            error: {
              name: "errorLogin",
              value:
                e.response.data.errors.User?.[0] ??
                e.response.data.errors.mainErrors,
            },
          });
        },
      })
    );
  }

  function resendVerifyEmail() {
    userApi
      .resendVerify()
      .then((res) => {
        Snackbar(translate("emailSent"), "success");
        setError(undefined);
      })
      .catch((e) =>
        setError({
          status: LoginErrorStatus.EmailIsNotVerified,
          error: {
            name: "resendEmail",
            value: e.response.data.errors.mainErrors[0],
          },
        })
      );
  }

  return (
    <LoginPage
      submitHandler={submitHandler}
      resendEmail={resendVerifyEmail}
      error={error}
    />
  );
}
