import { AxiosResponse } from "axios";
import { Snackbar } from "components/general/Snackbar";

export default function reportHttpErrors(
  response: AxiosResponse,
  snack: typeof Snackbar
) {
  const errors = response.data.errors;
  function getFirstError(errors: any, depth = 1) {
    for (const key in errors) {
      if (Array.isArray(errors[key]) && errors[key].length > 0) {
        return errors[key][0];
      } else if (typeof errors[key] === "object" && depth > 1) {
        return getFirstError(errors[key], depth - 1);
      }
    }
    return;
  }
  // refact. later on to switch case with enum for error http status
  if (response?.status === 400) {
    snack(getFirstError(errors), "error");
  } else if (response?.status === 406) {
    snack(getFirstError(errors), "warning");
  } else {
    snack("Service Connection Problem.", "error");
  }
}
