import i18n from 'localization/i18n';
import React from 'react';
import { ToastContainer, toast, ToastOptions, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const snackStyle = {
  fontFamily:"Alexandria,Segoe UI,Arial,Roboto,Helvetica Neue,sans-serif,Segoe UI,Segoe UI Light",
};

export const Snackbar = ( message: string, type: 'success' | 'error' | 'info' | 'warning' , duration?: number ) => {
  
  const options: ToastOptions = {
    position: "top-center",
    autoClose: duration ?? 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme:'colored',
    type: type as ToastOptions['type'],
    transition: Slide,
    rtl: i18n.language == 'ar',
    toastId:message,
    style:snackStyle
  };
  if(!toast.isActive(message)) toast(message, options)
}
const SnackbarApp: React.FC = () => (
  <ToastContainer limit={3} bodyStyle={snackStyle}/>
);

export default SnackbarApp;
