import Ticket from "amnPos/ticket/Ticket";
import {
  PosHandler,
  HandlerProps,
  IHandlerResult,
  HandlerStatus,
} from "../../../models/handlers/handler";

export class TotalHandler extends PosHandler<Ticket> {
  protected processHandle(
    props: HandlerProps<Ticket>
  ): Promise<IHandlerResult<Ticket>> {
    const { item: ticket, value } = props;

    if (value) {
      ticket.total = value as number;
    } else
      ticket.total = ticket.ticketItems.reduce(
        (total, item) => total.plus(item.net),
        0
      );

    return Promise.resolve({
      item: ticket,
      status: HandlerStatus.Success,
    });
  }
}
