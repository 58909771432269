export enum AmeenColors {
  Background = "#FFFFFF", // White
  Background2 = "#F6F6F6",
  border = "#DBDBDB",
  Foreground = "#202020", // Dark Gray
  Primary = "#1976D2", // Cyan
  PrimaryMid = "#17A6B2", // rgb(23, 166, 178)
  PrimaryDark = "#2e75b6", // rgb(46, 117, 182)
  Green = "#0ACC70", // rgb(10, 204, 112)
  GreenShiny = "#8CC63F", // rgb(140, 198, 63)
  GreenDark = "#056839", // rgb(5, 104, 57)
  GreenLight = "#7EAB40", // rgb(126, 171, 64)
  Secondary = "#E38800", // rgb(252, 176, 64)
  SecondaryMid = "#F7941E", // rgb(247, 148, 30)
  SecondaryLight = "#FFC300", // rgb(255, 195, 0)
  SecondaryDark = "#BF9000", // rgb(191, 144, 0)
  BlueGray = "#8497B0", // rgb(132, 151, 176)
  DarkGray = "#121212", // rgb(18, 18, 18)
  Gray = "#1e1e1e", // rgb(30, 30, 30)
  Disabled = "#8CBBE9",
  Black = "#171832",
  GrayWhite = "#80808B",
  GrayHeader = "#95A9B8",
  BabyBlue = "#F2F9FE",
  SoftGray = "#F1F1F4",
  HardBlack = "#020B12",
  HardBlue = "#145EA8",
  SkyBlue = "#E8F1FB",
  OffWhite = "#F4F4F4",
  SemiWhite = "#F2F2F2",
  SomeWhite = "#E4E6EF",
  HardGray = "#666777",
  BlueHover = "#edf4fb",
  SomeBlack = "#B5C2CE",
  Red = "#C62828",
  GreenLink = "#17C653",
  GreenCrystalGlass = "#DFFFEA",
  BorderGray = "#E2E8F0",
  BorderLightGray = "#DDE1E4",
  SeparatorGray = "#F3F6F9",
  ListGray = "#F5F8FA",
  ErrorRed = "#DA1E28",
  SuccessGreen = "#20C997",
  BrightBlue = "#1976D2",
  Transparent = "#00000000",
  DimGray = "#9E9E9E",
  SoftBlue = "#CCEAFF",
  DarkSoftBlue = "#002D4C",
  WhiteShade = "#F9F9F9",
  LabelGray = "#7A8B98",
  EqualBlue = "#00A3FF",
  MintGreen = "#E8FAEE",
  ShadeGreen = "#17C653",
  LightShadeGreen = "#F5F6F6",
  CharcoalBlue = "#25323E",
  PaleBlue = "#F0F4F8",
  PaleLavender = "#9FA6AD",
  LightShadeGray = "#E0E0E0",
  SilverSand = "#F2F2F2",
  LightCharochal = "#9A9A9A",
  WhiteSmoke = "#F8F8F8",
  LightSkyBlue = "#F3F8FB",
  HeaderGray = "#A1A5B7",
  BorderColor = "#D6E0E9",
  ListItemNotSelectedColor = "#ECEFF1",
  GrayBlued = "#637582",
  LavenderMist = "#E9E7FD",
  PeriwinkleBlue = "#6D62E3",
  NeutralGrey = "#939393",
  RedGrey = "#FCE8E9",
  EmeraldGreen = "#50CD89",
  JadeGreen = "#009C6E",
  PaleMintGreen = "#E9FAF5",
  SoftPink = "#FDEEEE",
  DarkRed = "#C61C1D",
  Gold = "#FF9843",
}

export enum AmeenColorsDark {
  Background = "#121212;", // DarkGray
  ImageBackground = "#0D0D0D;",
  SomeBlack = "#393939",
  Foreground = "#F0F0F0", // Off White
  Primary = "#1976D2", // Cyan
  PrimaryMid = "#008798", // rgb(0, 135, 152)
  PrimaryDark = "#2e75b6", // rgb(46, 117, 182)
  Green = "#0ACC70", // rgb(10, 204, 112)
  GreenShiny = "#8CC63F", // rgb(140, 198, 63)
  GreenDark = "#056839", // rgb(5, 104, 57)
  GreenLight = "#7EAB40", // rgb(126, 171, 64)
  Secondary = "#FCB040", // rgb(252, 176, 64)
  SecondaryMid = "#F7941E", // rgb(247, 148, 30)
  SecondaryLight = "#FFC300", // rgb(255, 195, 0)
  SecondaryDark = "#BF9000", // rgb(191, 144, 0)
  BlueGray = "#8497B0", // rgb(132, 151, 176)
  DarkGray = "#121212", // rgb(18, 18, 18)
  Gray = "#1e1e1e", // rgb(30, 30, 30)
  Disabled = "#C7C7C7",
  OffWhite = "#F4F4F414",
  HardGray = "#A5A5A5",
  HardBlack = "#E2E2E2",
  SoftGray = "#2E2E2E",
  OffGray = "#65717A",
  BabyBlue = "#64B5F6",
  BlueHover = "#1d2c3b",
  DialogBlack = "#1E1E1E",
  HardRed = "#D63232",
  HeaderGray = "#A1A5B7",
  GrayBlued = "#637582",
  LavenderMist = "#E9E7FD",
  PeriwinkleBlue = "#6D62E3",
}
