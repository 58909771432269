import { store } from "app/store";
import {
  TransactionTypes,
  TicketStatus,
  OrderType,
} from "models/common-props/TransactionTypes";
import { TicketPaymentGetway } from "models/PaymentModel";

import { NIL } from "uuid";

export default interface Ticket {
  id: string;
  number: number;
  code: string;
  note: string;
  total: number;
  totalPaid: number;
  taxTotal: number;
  type: TransactionTypes;
  orderType: OrderType;
  isDiscountPercentage: boolean;
  discountValue: number;
  status: TicketStatus;
  additionTime: Date | string;

  shiftCode: string;
  posId: string;
  employeeId: string;
  employee: string;
  salesmanId: string | null;
  salesman: string | null;
  clientId: string | null;
  client: string | null;
  ticketPaymentGetways: TicketPaymentGetway[];
  ticketItems: TicketItem[];
}

export interface TicketItem {
  id: string;
  number: number;
  ticketId: string;
  itemId: string;
  item: string | null;
  image?: string;
  price: number;
  qty: number;
  discountPercent: number;
  discountValue: number;
  giftQty: number;
  value: number;
  total: number;
  net: number;
  taxValue: number;
  unit: string | null;
  unitId: string;
  salesmanId: string | null;
  salesman: string | null;
  isReseted: boolean;
  barcode?: string;
  giftsValue: number;
  giftsPercent: number;
}

export const emptyTicketItem: TicketItem = {
  id: NIL,
  number: 0,
  ticketId: NIL,
  itemId: NIL,
  item: "",
  image: "",
  price: 0,
  qty: 0,
  discountPercent: 0,
  discountValue: 0,
  giftQty: 0,
  value: 0,
  total: 0,
  net: 0,
  taxValue: 0,
  unit: "",
  unitId: NIL,
  salesmanId: NIL,
  salesman: "",
  isReseted: false,
  barcode: "",
  giftsValue: 0,
  giftsPercent: 0,
};

export interface ItemDiscount {
  discPercent?: number;
  discValue?: number;
}

export const ticketDto = (ticket: Ticket) => {
  return {
    ...ticket,
    salesman: null,
    salesmanId: null,
    client: null,
    employeeId: store.getState().employeeSettings.id,
    shiftId: store.getState().shiftInfo.id,
    shift: null,
    ticketItems: ticket.ticketItems.map((item, index) => {
      return {
        ...item,
        number: index,
        ticketId: ticket.id,
        salesman: null,
        salesmanId: null,
        unit: null,
        item: null,
      };
    }),
  } as Ticket;
};

export interface ITicketItemsTotals {
  subTotal: number;
  itemDiscount: number;
  vat: number;
  total: number;
}

export const emptyTicketItemsTotals: ITicketItemsTotals = {
  subTotal: 0,
  itemDiscount: 0,
  vat: 0,
  total: 0,
};

export interface ITicketPaymentsTotals {
  transactionValue: number;
  collected: number;
  deferredAmount: number;
}

export const emptyTicketPaymentsTotals: ITicketPaymentsTotals = {
  transactionValue: 0,
  collected: 0,
  deferredAmount: 0,
};

export const emptyTicket: Ticket = {
  id: NIL,
  number: 0,
  code: "",
  note: "",
  total: 0,
  totalPaid: 0,
  taxTotal: 0,
  orderType: OrderType.none,
  type: TransactionTypes.sales,
  isDiscountPercentage: false,
  discountValue: 0,
  status: TicketStatus.new,
  additionTime: "",
  shiftCode: "",
  posId: "",
  employeeId: NIL,
  employee: "",
  salesmanId: null,
  salesman: null,
  clientId: null,
  client: null,
  ticketPaymentGetways: [],
  ticketItems: [],
};

export type CustomizedTicketDocuments = { [key: string]: string };
