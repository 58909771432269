import "extensions/numberExtensions";
import { TicketItem } from "amnPos/ticket/Ticket";
import {
  PosValidator,
  ValidateResault,
  IValidatorProps,
  ValidateStatus,
} from "../../../models/validators/validator";

export class QtyValidator extends PosValidator<TicketItem> {
  public validate(props: IValidatorProps<TicketItem>): ValidateResault {
    const { item, value } = props;

    const qty = Number(value as number) || Number(item.qty as number);

    if (qty.isLessThan(0))
      return {
        status: ValidateStatus.Error,
        errorMsg: "valueIsLessThanZero",
      };

    return { status: ValidateStatus.Success };
  }
}
