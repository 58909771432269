import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import userReducer from "adminPanel/user/userSlice";
import DataGridSlice from "slices/grid/DataGridSlice";
import PosItemsGridSlice from "slices/posGrid/PosItemsGridSlice";
import TicketReducer from "amnPos/ticket/TicketSlice";
import PaymentSlice from "amnPos/ticket/payment/PaymentSlice";
import PosSettingReducer from "amnPos/slices/PosSettingSlice";
import EmployeeSettingReducer from "amnPos/slices/EmployeeSettingSlice";
import LocalRouterReducer from "slices/currentPage/localRouterSlice";
import ShiftInfoSlice from "amnPos/slices/ShiftInfoSlice";
import barcodeSlice from "slices/barcode/barcodeSlice";
import LanguagesSlice from "adminPanel/settings/languages/languageSlice";
import RestTicketReducer from "amnRestaurants/ticket/restTicketSlice";
import RestaurantsMenuSlice from "slices/restaurantsMenuSlice/RestaurantsMenuSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["counter"], // list here reducers you want to persist: use reducer name as mentioned in combineReducers function
};

const reducers = combineReducers({
  user: userReducer,
  dataGrid: DataGridSlice,
  restMenu: RestaurantsMenuSlice,
  posItemsGridSlice: PosItemsGridSlice,
  ticket: TicketReducer,
  payment: PaymentSlice,
  posSetting: PosSettingReducer,
  employeeSettings: EmployeeSettingReducer,
  localRouter: LocalRouterReducer,
  shiftInfo: ShiftInfoSlice,
  barcode: barcodeSlice,
  languages: LanguagesSlice,
  restTicket: RestTicketReducer,
});

const persistingReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistingReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
