import {
  Stack,
  Button,
  Box,
  Typography,
  Link,
  Grid2,
  CardMedia,
} from "@mui/material";
import useTranslate from "hooks/useTranslate";
import { useState, useEffect } from "react";
import amnPosApi from "api/amnPosApi";
import useAmnNavigate from "hooks/useAmnNavigate";
import reportHttpErrors from "helpers/reportHttpErrors";
import { AppType } from "constants/AppType";
import { useAppDispatch } from "app/hooks";
import useShift from "amnPos/shiftsAndExternals/shift/useShift";
import { signoutAsync } from "adminPanel/user/userSlice";
import { getShiftInfoAsync } from "amnPos/slices/ShiftInfoSlice";
import FilterListBox, {
  IFilterListBoxItem,
} from "amnPos/generalComponent/FilterListBox";
import useIsSmUp from "hooks/useIsSmUp";
import i18n from "localization/i18n";
import logo from "/images/SignLogo.svg";
import shap1 from "/images/shap1.svg";
import shap2 from "/images/shap2.svg";
import shap3 from "/images/shap3.svg";
import illustration from "/images/illustration & font.svg";
import { AmeenColors } from "constants/ameenColors";
import LanguageSelector from "components/general/LanguageSelector";
import { Snackbar } from "components/general/Snackbar";
import SignLogo from "components/icons/SignLogo";
export enum POSType {
  POS,
  Restaurants,
}
export interface IPosAutoComplete {
  id: string;
  name: string;
  posType: POSType;
}

export default function UserHomePage() {
  const { translate } = useTranslate("pos.userHome");
  const { amnNavigate } = useAmnNavigate();
  const [posList, setPosList] = useState<IPosAutoComplete[]>([]);
  const [pos, setPos] = useState<IPosAutoComplete | null>(null);
  const dispatch = useAppDispatch();
  const { ValidateShift } = useShift();
  function HandlePosTypeNavigation(posType: POSType): void {
    switch (posType) {
      case POSType.Restaurants:
        amnNavigate("/restaurants", AppType.Organizations, {
          replace: true,
        });
        return;
      default:
        amnNavigate("/pos", AppType.Organizations, { replace: true });
        return;
    }
  }
  useEffect(() => {
    amnPosApi
      .GetPosList()
      .then((res) => setPosList(res.data))
      .catch((e) => {
        reportHttpErrors(e.response, Snackbar);
      });
  }, [Snackbar]);

  const handleClick = () => {
    localStorage.setItem("posId", pos?.id ?? "");
    localStorage.setItem("posType", String(pos?.posType ?? POSType.POS));
    dispatch(getShiftInfoAsync({ posId: pos?.id ?? "" })).then(() => {
      ValidateShift(pos?.id!, () => {
        amnNavigate(
          `/${
            (pos?.posType ?? POSType.POS).toString() ===
            POSType.Restaurants.toString()
              ? "restaurants"
              : "pos"
          }`,
          AppType.Organizations,
          {
            replace: true,
          }
        );
      });
    });
  };

  const handleSignOut = () => {
    dispatch(signoutAsync());
  };
  const isSmUp = useIsSmUp();
  const style = {
    container: {
      height: "100vh",
    },
    cardHeader: {
      alignItems: "center",
    },
    containerLogin: {
      display: "flex",
      justifyContent: "center",
      right: 0,
    },
    containerImage: {
      backgroundImage: "url('/images/BG.svg')",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      position: "relative",
    },
    shap1: {
      width: "7vw",
      right: 0,
      position: "absolute",
      objectFit: "contain",
      transform: i18n.language == "ar" ? "scaleX(-1)" : "",
    },
    shap2: {
      width: "13vw",
      bottom: 0,
      position: "absolute",
      objectFit: "contain",
      transform: i18n.language == "ar" ? "scaleX(-1)" : "",
    },
    logo: {
      width: "8vw",
      top: "2%",
      left: "3%",
      position: "absolute",
      objectFit: "contain",
    },
    shap3: {
      width: "13vw",
      right: 0,
      bottom: 0,
      position: "absolute",
      objectFit: "contain",
      transform: i18n.language == "ar" ? "scaleX(-1)" : "",
    },
    illustration: {
      top: "23%",
      position: "absolute",
      objectFit: "cover",
    },
    containerMobileLog: {
      backgroundImage: "url('/images/BGMobile.svg')",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      justifyContent: "center",
      alignItems: "center",
      paddingX: 2,
      height: "85dvh",
    },
    cardContent: {
      width: isSmUp ? "35vw" : "100dvw",
      justifyContent: "center",
    },
    Link: {
      textDecoration: "none",
      color: AmeenColors.BrightBlue,
      fontWeight: 500,
    },
    langSelect: {
      mobileView: {
        color: AmeenColors.BrightBlue,
        width: "100%",
      },
      default: {
        top: 22,
        right: i18n.language == "en" ? 5 : "unset",
        left: i18n.language == "ar" ? 5 : "unset",
        color: "white",
      },
    },
  };
  const Images = (
    <Stack position={"relative"} height={"100%"} width={"50%"}>
      <Stack position={"fixed"} height={"100%"} width={"50%"}>
        <CardMedia component={"img"} image={logo} sx={style.logo}></CardMedia>
        <CardMedia component={"img"} image={shap1} sx={style.shap1}></CardMedia>
        <CardMedia component={"img"} image={shap2} sx={style.shap2}></CardMedia>
        <CardMedia component={"img"} image={shap3} sx={style.shap3}></CardMedia>
        <CardMedia
          component={"img"}
          image={illustration}
          sx={style.illustration}
        ></CardMedia>
      </Stack>
    </Stack>
  );
  const cardContent = (
    <Stack style={style.cardContent}>
      <Stack alignItems={"center"} textAlign={"center"}>
        <Box width={"auto"} height={"auto"}>
          <SignLogo width={"210px"} height={"190px"} />
        </Box>
        <Typography mt={2} mb={"12px"} fontSize={"28px"} fontWeight={600}>
          {translate("choosePOS")}
        </Typography>

        <Typography mb={5} fontSize={"16px"} color={"#7A8B98"} fontWeight={300}>
          {translate("choosePosWelcome")}
        </Typography>
      </Stack>
      {posList && (
        <FilterListBox
          data={posList}
          onSelectedItemChanged={(item: IFilterListBoxItem | null) =>
            setPos(posList.filter((pos) => pos.id === item?.id)[0])
          }
        />
      )}
      <Stack alignItems="center" width={"100%"}>
        <Button
          fullWidth
          sx={{
            height: "48px",
            fontWeight: "350",
            "&:disabled": {
              backgroundColor: "#8CBBE9",
              color: "white",
              marginTop: "11px",
            },
          }}
          variant="contained"
          disabled={pos === null}
          onClick={handleClick}
        >
          {translate("ok")}
        </Button>
        <Typography
          mt="12px"
          mb="0px"
          fontSize={"14px"}
          color={"#7A8B98"}
          fontWeight={400}
        >
          <Link component="button" onClick={handleSignOut} style={style.Link}>
            {translate("signOut")}
          </Link>
        </Typography>
        <Stack
          sx={isSmUp ? style.langSelect.mobileView : style.langSelect.default}
        >
          <LanguageSelector />
        </Stack>
      </Stack>
    </Stack>
  );

  return isSmUp ? (
    <Grid2 container sx={style.container}>
      <Grid2 size={6} sx={style.containerImage}>
        {Images}
      </Grid2>
      <Grid2 size={6} sx={style.containerLogin}>
        {cardContent}
      </Grid2>
    </Grid2>
  ) : (
    <Grid2 container sx={style.containerMobileLog}>
      {cardContent}
    </Grid2>
  );
}
