import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import StandardTextField from "components/general/StandardTextField";
import { forwardRef, useState } from "react";

const PasswordTextField = forwardRef((props: any, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  const [newDesign, setNewDesign] = useState(false);
  const togglePassword = () => setShowPassword(!showPassword);
  const { startIcon, endIcon,NewDesign,size, margin  , ...other } = props;

  return(
    <StandardTextField
    
    margin={margin}
    size={size}
      ref={ref}
      {...other}
      type={showPassword ? "text" : "password"}
      startIcon={startIcon}
      endIcon={
        <IconButton
          aria-label="toggle password visibility"
          onClick={togglePassword}
          onMouseDown={togglePassword}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      }
    />
    )
  })
export default PasswordTextField
