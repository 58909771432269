import {
  Box,
  InputAdornment,
  ListItem,
  ListItemText,
  MenuList,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { memo, useState } from "react";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import PointOfSaleOutlinedIcon from "@mui/icons-material/PointOfSaleOutlined";
import { AmeenColors, AmeenColorsDark } from "constants/ameenColors";
import useTranslate from "hooks/useTranslate";

export interface IFilterListBoxItem {
  id: string;
  name: string;
}

export interface IFilterListBoxProps {
  data: any;
  onSelectedItemChanged: (item: IFilterListBoxItem | null) => void;
}

function FilterListBox(props: IFilterListBoxProps) {
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === "dark";
  const [selectedItem, setSelectedItem] = useState<IFilterListBoxItem | null>();
  const [searchTerm, setSearchTerm] = useState("");
  const { translate } = useTranslate("pos.userHome");
  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
    setSelectedItem(null);
    props.onSelectedItemChanged(null);
  };
  
  const filteredItems =
    props.data &&
    props.data.filter((item: IFilterListBoxItem) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  return (
    <Box
      sx={{
        border: `1px solid ${isDarkTheme ? "#303348" : "#B5C2CE"}`,
        borderRadius: "4px",
      }}
    >
      <Box
        sx={{
          borderRadius: "4px 4px 0 0",
          borderBottom: `1px solid ${isDarkTheme ? "#56596E" : "#B5C2CE"}`,
          background: isDarkTheme ? "#3A3E5A" : "#F8F9FA",
          padding: 1.5,
        }}
      >
        <TextField
          size="small"
          sx={{
            background: isDarkTheme ? "#303348" : AmeenColors.OffWhite,
          }}
          placeholder={translate("searchForPos")}
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PointOfSaleOutlinedIcon
                  sx={{
                    color: isDarkTheme ? "#D0CDE3" : AmeenColors.CharcoalBlue,
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon
                  sx={{
                    color: isDarkTheme ? "#D0CDE3" : AmeenColors.CharcoalBlue,
                    opacity: isDarkTheme ? 1 : 0.2,
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        sx={{
          maxHeight: 250,
          minHeight: 250,
          overflow: "auto",
          background: isDarkTheme ? "#303348" : AmeenColors.Background,
          padding: 0,
          "&::-webkit-scrollbar": {
            width: "12px",
          },
          "&::-webkit-scrollbar-track": {
            background: isDarkTheme ? "#303348" : AmeenColors.Background,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: isDarkTheme ? "#3E4055" : "#F8F9FA",
            "&:hover": {
              background: isDarkTheme ? "#3A3E5A" : AmeenColors.BorderLightGray,
            },
          },
        }}
      >
        <MenuList
          style={{
            background: isDarkTheme ? "#303348" : AmeenColors.Background,
          }}
        >
          {filteredItems &&
            filteredItems.map((item: IFilterListBoxItem) => (
              <ListItem
                sx={{
                  backgroundColor: isDarkTheme
                    ? selectedItem === item
                      ? AmeenColors.PrimaryDark
                      : "inherit"
                    : selectedItem === item
                    ? AmeenColors.SkyBlue
                    : "inherit",
                  "&:hover": {
                    backgroundColor: isDarkTheme
                      ? selectedItem === item
                        ? AmeenColors.Primary
                        : "#3E4055"
                      : selectedItem === item
                      ? "#DDEDFF"
                      : AmeenColors.LightShadeGreen,
                  },
                }}
                onClick={() => {
                  setSelectedItem(item);
                  props.onSelectedItemChanged(item);
                }}
                key={item.id}
              >
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        color: isDarkTheme
                          ? selectedItem === item
                            ? "#FFFFF"
                            : "#D0CDE3"
                          : selectedItem === item
                          ? AmeenColors.Primary
                          : AmeenColors.CharcoalBlue,
                        fontFamily: "Alexandria",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "300",
                        lineHeight: "150%",
                        letterSpacing: "0.15px",
                      }}
                    >
                      {item.name}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
        </MenuList>
      </Box>
    </Box>
  );
}
export default memo(FilterListBox);
