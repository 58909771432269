import { NIL, v4 as uuid } from "uuid";
import IStoreDto from "./IStoreDto";
import { ILocalization } from "adminPanel/preparedMaterials/IPreparedMaterials";

export interface IArea {
  id: string;
  name: string;
  countryId: string;
  countryName: string;
  code: string;
  subDivisionId: string;
  subDivisionName: string;
}

export const EmptyArea: IArea = {
  id: NIL,
  name: "",
  countryId: NIL,
  countryName: "",
  code: "",
  subDivisionId: NIL,
  subDivisionName: "",
};

export interface ISalesman {
  id: string;
  name: string;
}

export interface IStoreArea {
  id: string;
  storeId: string;
  area: IArea | undefined;
  delivery: boolean;
  deliveryFees: number;
}

export const EmptyStoreArea: IStoreArea = {
  id: NIL,
  storeId: "",
  area: EmptyArea,
  delivery: true,
  deliveryFees: 0,
};

export interface IDriver {
  id: string;
  name: string;
}

export interface IStoreDriver {
  id: string;
  isActive: boolean;
  storeId: string;
  driver?: IDriver;
}

export const EmptyStoreDriver: IStoreDriver = {
  id: NIL,
  isActive: true,
  storeId: NIL,
  driver: undefined,
};

export interface IStoreSalesman {
  id: string;
  storeId: string;
  salesman?: ISalesman;
  isActive: boolean;
  isDefault: boolean;
}

export const EmptyStoreSalesman: IStoreSalesman = {
  id: NIL,
  storeId: NIL,
  salesman: undefined,
  isDefault: false,
  isActive: true,
};

export interface IChildStore {
  id: string;
  name: string;
}
export interface IOtherStore {
  id: string;
  parentStoreId: string;
  childStore?: IChildStore;
}

export const EmptyOtherStore: IOtherStore = {
  id: NIL,
  parentStoreId: NIL,
  childStore: undefined,
};

export interface IStoreAutoComplete {
  id: string;
  name: string;
}

export const emptyAutoCompleteStore: IStoreAutoComplete = {
  id: NIL,
  name: "",
};

export interface IStorePrinter {
  id: string;
  name: string;
  printerName: string;
  paperSize: string;
  storeId: string;
}

export const EmptyStorePrinter: IStorePrinter = {
  id: NIL,
  name: "",
  printerName: "",
  paperSize: "",
  storeId: "",
};

export interface IStorePrinterCustomizations {
  id: string;
  name: string;
  tagIds: string[];
  tagName?: string;
  storePrinterId: string;
  printerName?: string;
  storeId: string;
}

export const EmptyStorePrinterCustomizations: IStorePrinterCustomizations = {
  id: NIL,
  name: "",
  tagIds: [],
  tagName: "",
  storePrinterId: "",
  printerName: "",
  storeId: NIL,
};

export default interface IStore {
  id: string;
  localizations: ILocalization[];
  areaId: string;
  printingServiceUrl: string;
  useReturnCoupons: boolean;
  returnCouponeValidFor: number;
  area?: IArea | null;
  unidentifiedAreas?: boolean;
  deliveryFees?: number;
  isStoreIsRestaurant?: boolean;
  useFastService?: boolean;
  useFullService?: boolean;
  deliveryFromStore?: boolean; 
  isDeliveryWorkingTimeBounded: boolean;
  deliveryFromTime: Date | string | null;
  deliveryToTime: Date | string | null;
  storeAreas: IStoreArea[];
  storeDrivers: IStoreDriver[];
  storeSalesmen: IStoreSalesman[];
  childernStores: IOtherStore[];
  storePrinters: IStorePrinter[];
  storePrinterCustomizations: IStorePrinterCustomizations[];
}



export function mapStoreToDto(store: IStore): IStoreDto {
  const oldToNewPrinterIdMap = new Map<string, string>();

  const storeDto: IStoreDto = {
    id: store.id,
    localizations: store.localizations,
    returnCouponeValidFor: store.returnCouponeValidFor,
    useReturnCoupons: store.useReturnCoupons,
    printingServiceUrl: store.printingServiceUrl,
    areaId: store.area ? store.area.id : NIL,
    unidentifiedAreas: store.unidentifiedAreas,
    deliveryFees: store.deliveryFees,
    isStoreIsRestaurant: store.isStoreIsRestaurant,
    useFastService: store.useFastService,
    useFullService: store.useFullService,
    deliveryFromStore: store.deliveryFromStore,
    storeAreas: store.storeAreas.map((area: IStoreArea) => ({
      id: uuid(),
      storeId: store.id,
      areaId: area.area ? area.area.id : NIL,
      delivery: area.delivery,
      deliveryFees: area.deliveryFees,
    })),

    storeDrivers: store.storeDrivers.map((driver: IStoreDriver) => ({
      id: uuid(),
      isActive: driver.isActive,
      storeId: store.id,
      driverId: driver.driver ? driver.driver.id : NIL,
    })),

    storeSalesmen: store.storeSalesmen.map((salesman: IStoreSalesman) => ({
      id: uuid(),
      storeId: salesman.storeId,
      salesmanId: salesman.salesman ? salesman.salesman.id : NIL,
      isDefault: salesman.isDefault,
      isActive: salesman.isActive,
    })),

    childernStores: store.childernStores.map((childStore: IOtherStore) => ({
      parentStoreId: childStore.parentStoreId,
      childStoreId: childStore.childStore ? childStore.childStore.id : NIL,
    })),

    storePrinters: store.storePrinters.map((printer: IStorePrinter) => {
      const newId = uuid();
      oldToNewPrinterIdMap.set(
        printer.id,
        store.id === NIL ? newId : printer.id
      );
      return {
        id: store.id === NIL ? newId : printer.id,
        name: printer.name,
        printerName: printer.printerName,
        paperSize: printer.paperSize,
        storeId: printer.storeId,
      };
    }),

    storePrinterCustomizations: store.storePrinterCustomizations.map(
      (customization: IStorePrinterCustomizations) => ({
        id: uuid(),
        name: customization.name,
        tagIds: customization.tagIds,
        storePrinterId: oldToNewPrinterIdMap.get(customization.storePrinterId) ||
          customization.storePrinterId,
        storeId: customization.storeId,
      })
    ),
    isDeliveryWorkingTimeBounded: store.isDeliveryWorkingTimeBounded,
    deliveryFromTime: store.deliveryFromTime,
    deliveryToTime: store.deliveryToTime
  };

  return storeDto;
}

export function mapDtoToStore(storeDto: IStoreDto): IStore {
  const store: IStore = {
    id: storeDto.id,
    returnCouponeValidFor: storeDto.returnCouponeValidFor,
    useReturnCoupons: storeDto.useReturnCoupons,
    unidentifiedAreas: storeDto?.unidentifiedAreas,
    deliveryFees: storeDto.deliveryFees,
    printingServiceUrl: storeDto.printingServiceUrl,
    areaId: storeDto.areaId,
    area: storeDto.area ? storeDto.area : undefined,
    isStoreIsRestaurant: storeDto.isStoreIsRestaurant,
    useFastService: storeDto.useFastService,
    useFullService: storeDto.useFullService,

    storeAreas: storeDto.storeAreas
      ? storeDto.storeAreas.map((area) => ({
        id: area.id,
        storeId: area.storeId,
        area: {
          id: area.areaId,
          name: area.areaName || "",
          countryId: "",
          countryName: area.countryName || "",
          code: "",
          subDivisionId: "",
          subDivisionName: area.subdivisionName || "",
        },
        delivery: area.delivery,
        deliveryFees: area.deliveryFees,
      }))
      : [],
    storeDrivers: storeDto.storeDrivers
      ? storeDto.storeDrivers.map((driver) => ({
        id: driver.id,
        storeId: driver.storeId,
        isActive: driver.isActive,
        driver: {
          id: driver.driverId,
          name: driver.driverName || "",
        },
      }))
      : [],
    storeSalesmen: storeDto.storeSalesmen
      ? storeDto.storeSalesmen.map((salesman) => ({
        id: salesman.id,
        storeId: salesman.storeId,
        salesman: {
          id: salesman.salesmanId,
          name: salesman.salesmanName || "",
        },
        isDefault: salesman.isDefault,
        isActive: salesman.isActive,
      }))
      : [],
    childernStores: storeDto.childernStores
      ? storeDto.childernStores.map((childStore) => ({
        id: childStore.childStoreId,
        parentStoreId: childStore.parentStoreId,
        childStore: {
          id: childStore.childStoreId,
          name: childStore.childStoreName || "",
        },
      }))
      : [],
    storePrinters: storeDto.storePrinters
      ? storeDto.storePrinters.map((printer) => ({
        id: printer.id,
        name: printer.name,
        printerName: printer.printerName,
        paperSize: printer.paperSize,
        storeId: printer.storeId,
      }))
      : [],
    storePrinterCustomizations: storeDto.storePrinterCustomizations
      ? storeDto.storePrinterCustomizations.map((customization) => ({
        id: customization.id,
        name: customization.name,
        tagIds: customization.tagIds,
        tagName: customization.tagName,
        storePrinterId: customization.storePrinterId,
        printerName: customization.printerName,
        storeId: customization.storeId,
      }))
      : [],
    localizations: storeDto.localizations
      ? storeDto.localizations.map((name) => ({
        id: name.id,
        name: name.name,
        default: name.default,
        languageCode: name.languageCode,
      }))
      : [],
    deliveryFromStore:storeDto.deliveryFromStore,
    isDeliveryWorkingTimeBounded: storeDto.isDeliveryWorkingTimeBounded,
    deliveryFromTime: storeDto.deliveryFromTime,
    deliveryToTime: storeDto.deliveryToTime
  };

  return store;
}
