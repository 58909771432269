import { useAppDispatch, useAppSelector } from "app/hooks";
import { initialize, updateTicket } from "amnPos/ticket/TicketSlice";
import {
  TicketStatus,
  TransactionTypes,
} from "models/common-props/TransactionTypes";
import {
  clientHandler,
  itemsHandler,
  statusHandler,
  typeHandler,
} from "amnPos/handlers/ticketHandlers/GeneralTicketChain";
import Ticket, { emptyTicket, ticketDto } from "./Ticket";
import useExecuteHandler, {
  ICallbackTypes,
} from "models/handlers/useExecuteHandler";
import { HandlerProps } from "models/handlers/handler";
import { IClient } from "models/PaymentModel";
import TicketApi from "./ticketApi";
import { store } from "app/store";
import { setLiftTicketsCount } from "adminPanel/user/userSlice";
import { Snackbar } from "components/general/Snackbar";
import reportHttpErrors from "helpers/reportHttpErrors";

export interface ITicketPorps {
  id?: string;
}

export function useTicket(props: ITicketPorps) {
  const ticket = useAppSelector((x) => x.ticket);

  const dispatch = useAppDispatch();
  const { executeHandler, executeHandlerWithCallback, executeHandleAsync } =
    useExecuteHandler();

  const getTicketFromLocalStore = () => {
    return ticket;
  };

  const createNewTicket = (type: TransactionTypes) => {
    executeHandler(typeHandler, { item: ticket, value: type }).then(
      ({ item, status }) => {
        dispatch(updateTicket(item));
      }
    );
    return ticket;
  };

  const onTicketItemsUpdate = (props: HandlerProps<Ticket>) => {
    executeHandler(itemsHandler, props).then(({ item, status }) => {
      dispatch(updateTicket(item));
    });
  };

  const onTicketItemsUpdateAsync = async (props: HandlerProps<Ticket>) => {
    const result = await executeHandleAsync(itemsHandler, props);
    dispatch(updateTicket(result.item));
  };

  const changeStatus = (
    status: TicketStatus,
    callback: ICallbackTypes<Ticket>,
    t?: Ticket
  ) => {
    executeHandlerWithCallback(
      statusHandler,
      { item: t ? t : ticket, value: status },
      callback
    );
  };

  const onTicketAddClient = (
    client: IClient,
    callback: (result: Ticket) => void
  ) => {
    executeHandler(clientHandler, {
      item: ticket,
      value: { id: client.id, name: client.name },
    }).then((resp) => {
      dispatch(updateTicket(resp.item));
      callback(resp.item);
    });
  };

  const cancelOpenTicketsAction = async () => {
    const result = await TicketApi.CancelAllOpenedTicket(
      store.getState().shiftInfo.id!
    );

    if (result.data) dispatch(setLiftTicketsCount(0));
    return result.data;
  };

  function deleteSuccessCallBack(result: Ticket) {
    TicketApi.DeleteTicket(ticketDto(result))
      .then((res) => {
        dispatch(
          updateTicket({
            ...emptyTicket,
            orderType: store.getState().ticket.orderType,
          })
        );
      })
      .catch((e) => {
        reportHttpErrors(e.response, Snackbar);
      });
  }

  const cancelCurrentTicketAction = async () => {
    changeStatus(TicketStatus.canceled, {
      onSuccess: deleteSuccessCallBack,
    });
  };
  const getLiftTicketsCount = async () => {
    return TicketApi.getLiftTicketsCount(store.getState().shiftInfo.id!);
  };
  return {
    getTicketFromLocalStore,
    createNewTicket,
    onTicketItemsUpdate,
    onTicketItemsUpdateAsync,
    changeStatus,
    onTicketAddClient,
    cancelOpenTicketsAction,
    cancelCurrentTicketAction,
    getLiftTicketsCount,
  };
}
