import axios from "axios";
import getApiBaseUrl from "helpers/getApiBaseUrl";
import Ticket, {
  CustomizedTicketDocuments,
  ITicketItemsTotals,
} from "./Ticket";
import {
  OrderType,
  TransactionTypes,
} from "models/common-props/TransactionTypes";

const baseUrl = getApiBaseUrl();

function PayTicket(ticket: Ticket) {
  return axios.post(baseUrl + `Tickets/pay-ticket`, ticket);
}

function UploadTicket(ticket: Ticket) {
  return axios.post(baseUrl + `Tickets/upload-ticket`, ticket);
}

function GetTicketById(ticketId: string) {
  return axios.get(baseUrl + `Tickets/get-ticket/${ticketId}`);
}

function GetBasicTicketInfoById(ticketId: string) {
  return axios.get(`${baseUrl}Tickets/${ticketId}`);
}

function DeleteTicket(ticket: Ticket) {
  return axios.post(baseUrl + `Tickets/delete-ticket`, ticket);
}
function FinishedTicketCounts(shiftId: string) {
  return axios.get(`${baseUrl}tickets/finished-counts/${shiftId}`);
}

function GetNewTicket(
  shiftId: string,
  ticketType: TransactionTypes,
  orderType: OrderType
) {
  return axios.get<Ticket>(baseUrl + `Tickets/get-new-ticket`, {
    params: {
      shiftId,
      ticketType,
      orderType,
    },
  });
}

function getLiftTicketsCount(shiftId: string) {
  return axios.get<number>(
    baseUrl + `Tickets/get-lift-tickets-count/${shiftId}`
  );
}

function GetTicketItemsTotals(ticketId: string) {
  return axios.get<ITicketItemsTotals>(
    `${baseUrl}tickets/${ticketId}/items/totals`
  );
}

function GetTicketPaymentsTotals(ticketId: string) {
  return axios.get(`${baseUrl}tickets/${ticketId}/payments/totals`);
}

function CancelAllOpenedTicket(shiftId: string) {
  return axios.post<boolean>(
    baseUrl + `tickets/cancel-all-opened-ticket/${shiftId}`
  );
}

function GetPrintTicketDocument(id: string) {
  return axios.get<string>(baseUrl + `tickets/get-print-ticket-document/${id}`);
}

function GetCustomizedTicketDocuments(id: string) {
  return axios.get<CustomizedTicketDocuments>(
    baseUrl + `tickets/get-print-ticket-customized-documents/${id}`
  );
}

const TicketApi = {
  PayTicket,
  UploadTicket,
  GetTicketById,
  DeleteTicket,
  getLiftTicketsCount,
  FinishedTicketCounts,
  GetNewTicket,
  GetBasicTicketInfoById,
  GetTicketItemsTotals,
  GetTicketPaymentsTotals,
  CancelAllOpenedTicket,
  GetPrintTicketDocument,
  GetCustomizedTicketDocuments,
};

export default TicketApi;
