import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import Ticket, { TicketItem, emptyTicket } from "./RestTicket";
import {
  CurrencyPaymentMethod,
  IClient,
  TicketPaymentGetway,
} from "models/PaymentModel";

const initialState: Ticket = { ...emptyTicket };

export const RestTicketSlice = createSlice({
  name: "restTicket",
  initialState,
  reducers: {
    initialize: (state) => initialState,
    addTicketNote: (state, action: PayloadAction<string>) => {
      state.note = action.payload;
    },
    updateTicket: (state, action: PayloadAction<Ticket>) => {
      state = action.payload;
      return state;
    },
    addTicketItem: (state, action: PayloadAction<TicketItem>) => {
      state.ticketItems.push(action.payload);
      return state;
    },
    updateTicketItem: (state, action: PayloadAction<TicketItem>) => {
      state.ticketItems.map((o, key) => {
        if (o.id === action.payload.id) {
          state.ticketItems[key] = action.payload;
        }

        return state;
      });
    },
    deleteTicketItem: (state, action: PayloadAction<string>) => {
      state.ticketItems = state.ticketItems.filter(
        (o) => !action.payload.includes(o.id)
      );
      return state;
    },
    updatePayments: (state, action: PayloadAction<CurrencyPaymentMethod[]>) => {
      state.ticketPaymentGetways = action.payload.map((item) => {
        return {
          id: item.id,
          ticketId: state.id,
          currencyId: item.currencyId,
          currencyVal: item.currencyValue,
          payType: item.type,
          amount: item.paymentValue,
        } as TicketPaymentGetway;
      });
      return state;
    },
    addClientToTicket: (state, action: PayloadAction<IClient>) => {
      if (state.ticketItems.length > 0) {
        state.clientId = action.payload.id;
        state.client = action.payload.name;
      }
      return state;
    },
  },
});

export const {
  initialize,
  updateTicket,
  addTicketItem,
  updateTicketItem,
  deleteTicketItem,
  addClientToTicket,
  addTicketNote,
} = RestTicketSlice.actions;

export const selectRestTicketItem = (state: RootState) => state.ticket;

export default RestTicketSlice.reducer;
